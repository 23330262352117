.Home{
    width:93%;
    margin:1em auto;
}
.PageTileSection{
    display: flex;
}
.BookerForUser{
    border: 1px solid #ddd;
    border-bottom: none;
    border-right: none;
    padding: 5px 15px;
    width: 28%;
    flex: 1 1 auto;
    border-spacing: 0;
    border-collapse: collapse;
}
.BookerForUser a, .BookerForUser a:hover,.BookerForUser a:visited{
    color: black;
    text-decoration: none;
}
.userList{
    padding: 12px;
}
.userListDiv{
    display: flex;
    width: 100%;
    border-collapse: collapse;
    flex: 1 0 auto;
    flex-direction: row;
    border-right: 1px solid #dddd;
    border-spacing: 0;
    flex-wrap: wrap;
    border-bottom: 1px solid #dddd;
    /* max-height: 500px;
    overflow: auto; */
}
/* .userListDiv  div:nth-child(even){
    border-left: none;
    border-bottom: none;
}
.userListDiv  div:nth-child(odd){
    border-bottom: none;
}
.userListDiv  div:last-child{
    border-bottom: 1px solid #4f4e4d;
}
.userListDiv  div:nth-last-child(2){
    border-bottom:  1px solid #4f4e4d;;
} */
.PageTile{
    padding: 1em;
    margin: 0 0 0.5em 0;
    cursor: pointer;
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;   
    flex-direction: column;
    justify-content: center;
  
}
.UserTile{
    padding: 1em;
    margin: 0.5em 0;
    /* cursor: pointer; */
    background-color: white;
    display: flex;
    align-items: center;
    width: 100%;   
    flex-direction: column;
    justify-content: center;
}
.usersCard{
    font-weight: bold;
    font-size: 1.5rem;
    text-align: center;
}
.PageTileContent{
    padding: 0.5em;
    text-align: center;
}
.transparentBg{
    background-color: transparent!important;
}
.PageTile:hover{
    background-color: #4f4e4d;
    color: white;
}
.disabledHomeBtn{
    pointer-events: none;
    cursor:not-allowed;
    opacity: 0.5;
}