.clearDateFilter{
    margin-bottom: 15px;
    border: 1px solid black;
    border-left: none;
    color: black;
}
.clearProject{
    border: 1px solid black;
    border-left: none;
    color: black;
}
.clearDateFilter i{
    font-size:12px;
}
.clearDateFilter:hover{
   color: black;
}